<template>
  <div class="page-account">
    <div class="page-account-header"></div>
    <div class="page-account-container">
      <SocialSign></SocialSign>
      <div class="xz-login" v-if="forget">
        <el-form :model="forgetForm" :rules="rules" ref="forgetForm">
          <el-form-item prop="mobile">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-phone"
              v-model="forgetForm.mobile"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="captcha">
            <el-row :gutter="8">
              <el-col :span="16">
                <el-input
                  placeholder="请输入验证码"
                  prefix-icon="el-icon-set-up"
                  v-model="forgetForm.captcha"
                >
                </el-input>
              </el-col>
              <el-col :span="8">
                <el-button
                  class="captcha-btn"
                  :disabled="disabled"
                  @click="sendcode()"
                  >{{ btntxt }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button
              class="bigbtn"
              type="primary"
              @click="onForGet('forgetForm')"
              >找回密码</el-button
            >
          </el-form-item>
          <el-form-item>
            <router-link to="/login" class="go-login">登录</router-link>
            <router-link to="/register" class="go-register">
              注册账户</router-link
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="xz-login" v-if="resetpass">
        <el-form :model="upPwdForm" :rules="rules" ref="upPwdForm">
          <el-form-item prop="password">
            <el-input
              placeholder="至少六位密码，区分大小写"
              prefix-icon="el-icon-lock"
              v-model="upPwdForm.password"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="checkPass">
            <el-input
              placeholder="确认密码"
              prefix-icon="el-icon-lock"
              v-model="upPwdForm.checkPass"
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              class="bigbtn"
              type="primary"
              @click="onUpPwd('upPwdForm')"
              >更新密码</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <PageFooter></PageFooter>
  </div>
</template>
<script>
import SocialSign from './components/social-sign.vue';
import PageFooter from './components/page-footer.vue';

export default {
  name: 'Forget',
  components: {
    SocialSign,
    PageFooter
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.regForm.checkPass !== '') {
          this.$refs.regForm.validateField('checkPass');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      forgetForm: {
        mobile: '',
        captcha: ''
      },
      uppwdForm: {
        password: '',
        checkPass: ''
      },
      forget: true,
      resetpass: false,
      time: 0,
      disabled: false,
      btntxt: '获取验证码',
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur'
          }
        ],
        captcha: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur'
          }
        ],
        password: [
          {
            validator: validatePass,
            trigger: 'blur'
          }
        ],
        checkPass: [
          {
            validator: validatePass2,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    sendcode() {
      const reg = 11 && /^((13|14|15|17|18|19)[0-9]{1}\d{8})$/;
      if (this.forgetForm.mobile === '') {
        this.$message({
          message: '手机号不能为空',
          center: true
        });
        return;
      }
      if (!reg.test(this.forgetForm.mobile)) {
        this.$message({
          message: '请输入正确的手机号',
          center: true
        });
      } else {
        console.log(this.forgetForm.mobile);
        this.$message({
          message: '发送成功',
          type: 'success',
          center: true
        });
        this.time = 60;
        this.disabled = true;
        this.timer();
      }
    },
    timer() {
      if (this.time > 0) {
        this.time -= 1;
        this.btntxt = `${this.time}s后重新获取`;
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btntxt = '获取验证码';
        this.disabled = false;
      }
    },
    onForGet(forgetForm) {
      const that = this;
      that.$refs[forgetForm].validate((valid) => {
        if (valid) {
          that.forget = false;
          that.resetpass = true;
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    },
    onUpPwd(upPwdForm) {
      this.$refs[upPwdForm].validate((valid) => {
        if (valid) {
          this.$router.push({
            path: '/login'
          });
          return true;
        }
        console.log('error submit!!');
        return false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.page-account {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
}
.go-register {
  float: right;
}
.go-login {
  float: left;
}
.page-account-header {
  text-align: right;
  position: fixed;
  top: 16px;
  right: 24px;
}

.page-account-container {
  flex: 1;
  padding: 32px 0;
  text-align: center;
  width: 384px;
  margin: 0 auto;
  .goBack {
    display: flex;
    justify-content: space-between;
  }
}

.captcha-btn {
  width: 100%;
}

.xz-login {
  .page-account-auto-login {
    margin-bottom: 24px;
    text-align: left;
  }

  .page-account-auto-login a {
    float: right;
  }

  .page-account-other {
    margin: 24px 0;
    text-align: left;

    img {
      width: 24px;
      margin-left: 16px;
      cursor: pointer;
      vertical-align: middle;
      opacity: 0.7;
      transition: all 0.2s ease-in-out;
    }

    .page-account-to-login {
      text-align: center;
      margin-top: 16px;
    }
  }
}
</style>
